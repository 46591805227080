import { BaseGraph } from "./base_graph";

class AGFinance extends BaseGraph {
    constructor() {
        super('ag_finance');
    }

    postSetup($parent) {
        $parent.find('select').unbind('change').on('change', async (e) => {
            const el = $(e.currentTarget);
            const inputs = app.DOM.content.find('.grid-stack').find('input, select, a, button');
            inputs.attr('disabled', 'true').addClass('disabled');
            await app.DASHBOARD_CORE.rebuildGraph(this.GRIDSTACK_NAME, { ag_finance_value: el.val() });
            inputs.removeAttr('disabled').removeClass('disabled');
        });
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new AGFinance();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
